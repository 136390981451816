body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-breadcrumb {
  background-color: #f8f9fa; /* Set background color */
  padding: 10px;
  border-radius: 5px;
}

.custom-breadcrumb-item {
  font-size: 16px; /* Set font size */
}

.custom-breadcrumb-item.active {
  color: #007bff; /* Set active item color */
}