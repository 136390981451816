.register .card{
    height:500px;
    width:800px;
    background-color:#fff;
    position:relative;
    box-shadow:0 15px 30px rgba(0,0,0,0.1);
    font-family: 'Poppins', sans-serif;
    border-radius:20px;
}
.register .card .form{
    width:100%;
    height:100%;
    
    display:flex;
}
.register .card .left-side{
    width:35%;
    background-color:#dc3545;
    height:100%;
 border-top-left-radius:20px;
 border-bottom-left-radius:20px;
  padding:20px 30px;
  box-sizing:border-box;

}
/*left-side-start*/
.left-heading{
    color:#fff;
   
}
.steps-content{
    margin-top:30px;
    color:#fff;
}
.steps-content p{
    font-size:12px;
    margin-top:15px;
}
.progress-bar{
    list-style:none;
    font-size:13px;
    text-align: left;
    font-weight:700;
    counter-reset:container 0;
}
.progress-bar li{
       position:relative;
       margin-left:40px;
       margin-top:50px;
       counter-increment:container 1;
      color:#f6a6ae;
}
.progress-bar li::before{
    content:counter(container);
    line-height:25px;
    text-align:center;
    position:absolute;
    height:25px;
    width:25px;
    border:1px solid #f6a6ae;
    border-radius:50%;
    left:-40px;
    top:-5px;
    z-index:10;
    background-color:#dc3545;

     
}
.custom-card {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); /* Decent shadow effect */

    border-radius: 10px; /* Adjust border radius if needed */
    padding: 3px;
}
.custom-card .badge {
    padding: 10px 20px;
    border-radius: 20px;
    color: #000;
    border: 1px solid #ccc;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
    margin-right: 10px;
}
.custom-card .card-header{
background-color: #fff;
border: 0;
}

.progress-bar li::after{
    content: '';
    position: absolute;
    height: 90px;
    width: 2px;
    background-color: #f6a6ae;
    z-index: 1;
    left: -27px;
    top: -70px;
}


.progress-bar li.active::after{
    background-color: #fff;

}

.progress-bar li:first-child:after{
  display:none;  
}

/*.progress-bar li:last-child:after{*/
/*  display:none;  */
/*}*/
.progress-bar li.active::before{
    color:#fff;
      border:1px solid #fff;
}
.progress-bar li.active{
    color:#fff;
}
.d-none{
   display:none;   
}






















/*left-side-end*/
.container .card .right-side{
    width:65%;
    background-color:#fff;
    height:100%;
  border-radius:20px;
}
/*right-side-start*/
.main{
    display:none;
}
.active{
    display:block;
}
.main{
    padding:40px;
}
.main small{
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:2px;
    height:30px;
    width:30px;
    background-color:#ccc;
    border-radius:50%;
    color:yellow;
    font-size:19px;
}
.text{
    margin-top:20px;
}
.congrats{
    text-align:center;
}
.text p{
    margin-top:10px;
    font-size:13px;
    font-weight:700;
    color:#cbced4;
}
.input-text{
    margin:30px 0;
     display:flex;
    gap:20px;
}

.input-text .input-div{
    width:100%;
    position:relative;
    
}



input[type="text"]{
    width:100%;
    height:40px;
    border:none;
    outline:0;
    border-radius:5px;
    border:1px solid #cbced4;
    gap:20px;
    box-sizing:border-box;
    padding:0px 10px;
}
select{
    width:100%;
    height:40px;
    border:none;
    outline:0;
    border-radius:5px;
    border:1px solid #cbced4;
    gap:20px;
    box-sizing:border-box;
    padding:0px 10px;
}
.input-text .input-div span{
    position:absolute;
    top:10px;
    left:10px;
    font-size:14px;
    transition:all 0.5s;
}
.input-div input:focus ~ span,.input-div input:valid ~ span  {
    top:-15px;
    left:6px;
    font-size:10px;
    font-weight:600; 
}

.input-div span{
    top:-15px;
    left:6px;
    font-size:10px;
}
.buttons button{
    height:40px;
    width:100px;
    border:none;
    border-radius:5px;
    background-color:#0075ff;
    font-size:12px;
    color:#fff;
    cursor:pointer;
}
.button_space{
    display:flex;
    gap:20px;
    
}
.button_space button:nth-child(1){
    background-color:#fff;
    color:#000;
    border:1px solid#000;
}
.user_card{
    margin-top:20px;
    margin-bottom:40px;
    height:200px;
    width:100%;
    border:1px solid #c7d3d9;
    border-radius:10px;
    display:flex;
    overflow:hidden;
    position:relative;
    box-sizing:border-box;
}
.user_card span{
    height:80px;
    width:100%;
    background-color:#dfeeff;
}
.circle{
    position:absolute;
    top:40px;
    left:60px;
}
.circle span{
    height:70px;
    width:70px;
    background-color:#fff;
    display:flex;
    justify-content:center;
    align-items:center;
    border:2px solid #fff;
    border-radius:50%;
}
.circle span img{
    width:100%;
    height:100%;
    border-radius:50%;
    object-fit:cover;
}
.social{
    display:flex;
    position:absolute;
    top:100px;
    right:10px;
}
.social span{
    height:30px;
    width:30px;
    border-radius:7px;
    background-color:#fff;
    border:1px solid #cbd6dc;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-left:10px;
    color:#cbd6dc;

}
.social span i{
        cursor:pointer;
}
.heart{
    color:red !important;
}
.share{
        color:red !important;
}
.user_name{
    position:absolute;
    top:110px;
    margin:10px;
    padding:0 30px;
    display:flex;
    flex-direction:column;
    width:100%;
    
} 
.user_name h3{
    color:#4c5b68;
}
.detail{
    /*margin-top:10px;*/
   display:flex;
   justify-content:space-between;
   margin-right:50px;
}
.detail p{
    font-size:12px;
    font-weight:700;

}
.detail p a{
    text-decoration:none;
    color:blue;
}






.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}










.warning{
    border:1px solid red !important;
}


/*right-side-end*/
@media (max-width:750px) {
    .container{
        height:scroll;
       
        
    }
    .container .card {
        max-width: 350px;
        height:auto !important;
        margin:30px 0;
    }
    .container .card .right-side {
     width:100%;
            
    }
     .input-text{
         display:block;
     }
     
     .input-text .input-div{
  margin-top:20px;
    
}

    .container .card .left-side {
           
     display: none;
    }
}